<template>
    <app-wrapper>
        <template v-slot:child>
            <InventoryHeader :type="'PRODUCT_CATEGORY'" :title="'Categories'" />

            <template v-if="!categories">
                <Spinner />
            </template>

            <!-- desktop / mobile content display -->
            <div v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(68)">
                <div class="scrollmenu w-90 center">
                    <table class="w-100 tl mt3" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="w-80 pb3 pr5 box-border-bottom tl">
                                    <span class="flex items-center">
                                        <span>Name</span>
                                        <span>
                                            <span class="header-filter-sort-icon">
                                                <span v-if="state.isOpen && getTheValue == 'name'" class="expand sort1"
                                                    @click="sortTransactionsTable('asc', 'name')">
                                                    <img :src="require('@/assets/images/sort-up.png')"
                                                        class="directional-up" alt="" />
                                                </span>
                                                <span v-else class="expand sort1"
                                                    @click="sortTransactionsTable('desc', 'name')">
                                                    <img :src="require('@/assets/images/caret-down.png')"
                                                        class="directional-up" alt="" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </th>
                                <th class="pb3 pr5 box-border-bottom tl">Created At</th>
                            </tr>
                        </thead>

                        <template v-if="categories?.data?.length">
                            <template v-for="category of categories?.data" v-bind:key="category._id">
                                <tr class="font-w1" @click="handleClickedProduct(category._id)">
                                    <td class="pv3 pr4 box-border-bottom tl b">
                                        <router-link :to="{ name: 'Products', query: { category: category.name } }">
                                            <span>{{ category.name }}</span>
                                        </router-link>
                                        
                                    </td>
                                    <td class="pv3 pr4 box-border-bottom tl">
                                        <span>{{ formatDate(category.createdAt) }}</span>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </table>
                    <TableEmptyState v-if="!categories?.data?.length" :data-array="categories?.data" />
                </div>

                <Pagination v-if="categories?.data?.length" :pages="categories?.pagination" route-name="ProductCategories"
                    :onNavigate="onNavigate" />
            </div>
        </template>
    </app-wrapper>
</template>

<script>
    import { onMounted, computed, reactive, ref } from 'vue'
    import { useStore } from 'vuex'
    import { useRoute, useRouter } from 'vue-router'
    import { formatDate } from '@/utils/lib'
    import AppWrapper from '@/layout/AppWrapper'
    import InventoryHeader from '@/views/Inventory/widgets/InventoryHeader'
    import Pagination from '@/components/Pagination'

    import Spinner from '@/components/Spinner'
    import TableEmptyState from '@/components/TableEmptyState'
    // import Filter from '@/components/Filter'

    export default {
        name: 'Services',
        components: { AppWrapper, InventoryHeader, Pagination, Spinner, TableEmptyState },
        data: () => ({
            isOpen: true
        }),
        setup() {
            const router = useRouter()
            const store = useStore()
            const categories = computed(() => store?.state.Inventory.categories)
            const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
            const role = computed(() => store?.state?.Auth?.role)
            const route = useRoute()
            const getTheValue = ref('')

            const state = reactive({
                isOpen: true
            })

            const onNavigate = query => {
                store.dispatch('Inventory/getAllCategories', query)
            }

            const handleClickedProduct = id => {
                if (role?.value === 'admin' || role?.value === 'owner') {
                    router.push({ name: 'Service', params: { id } })
                }
            }

            onMounted(() => {
                const query = { limit: route.query?.limit || 25, page: route.query?.page || 1 }
                store.dispatch('Inventory/getAllCategories', query)
            })

            const refreshHandler = () => {
                store.dispatch('Inventory/getAllCategories')
            }

            const sortTransactionsTable = (mode, sortBy) => {
                state.isOpen = !state.isOpen
                getTheValue.value = sortBy
                const query = {
                    filterType: route.query.filterType || 'customrange',
                    limit: route.query.limit || 25,
                    page: route.query.page || 1,
                    sort: route.query.sort || sortBy,
                    mode: route.query.mode || mode,
                }

                store.dispatch('Inventory/getAllCategories', query)
            }

            return {
                state,
                categories,
                formatDate,
                handleClickedProduct,
                refreshHandler,
                onNavigate,
                role,
                rolePermissions,
                sortTransactionsTable,
                getTheValue
            }
        }
    }
</script>

<style scoped></style>